import React from "react"

import { Layout, Menus } from "../components"
import Seo from "../components/seo"

const MenusPage = () => {
  return (
    <Layout>
      <Seo
        title="Menu Thai restaurant in Montpelier, Vermont"
        description="Pho Thai Express restaurant's menus. The best Thai restaurant in Montpelier, Vermont, Authentic Thai noodle soup, beef noodle soup, ramen noodle, mango sticky rice, Thai ice tea, and boba tea."
      />
      <main>
        <Menus />
      </main>
    </Layout>
  )
}

export default MenusPage
